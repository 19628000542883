import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import BasicPage from '../components/basic-page'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { toPlainText } from '../lib/helpers'

export const query = graphql`
  query BasicPageTemplateQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
      id
      order
      title
      slug {
        current
      }
      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`

const BasicPageTemplate = props => {
  const { data, errors } = props
  const page = data && data.page

  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {page && <SEO title={page.title || 'Untitled'} description={toPlainText(page._rawBody)} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {page && <BasicPage {...page} />}
    </Layout>
  )
}

export default BasicPageTemplate
